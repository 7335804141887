import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { API } from "../util/config";
import axios from 'axios';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";

class login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notice: '',
            type: 'tab2',
            autoLogin: true,
            ipt_username: '',
            ipt_password: '',
            reCaptcha: ''
        };
    }

    componentDidMount(){
        this.getIPAddress()
    }

    async getIPAddress(){
        await axios.get(`https://api.ipify.org/?format=json`)   
            .then(res => {
                console.log(res.data.ip)
                localStorage.setItem('ip', res.data.ip)
            })
    }

    async login() {
        const body = {
            username: this.state.ipt_username,
            password: this.state.ipt_password,
            reCaptcha: this.state.reCaptcha
        }
        await axios.post(`${API}/login`, body)
                .then(res => {
                    const data = res.data
                    if (!data.status) {
                        console.log(data.message)
                        message.error(data.message);
                    } else {
                        this.setLogHistory(data.user.name)
                        message.success(data.message);
                        localStorage.setItem('user', JSON.stringify({ accessToken: data.accessToken, user: data.user }))
                        this.props.history.push("/manage");
                    }
                })
                .catch(err => {
                    console.log(err)
                    message.error("การเชื่อมต่อผิดพลาด!!");
                })
    }

    async setLogHistory(user){
        const body = {
            ip: localStorage.getItem('ip'),
            user: user,
            event: 'เข้าสู่ระบบ'
        }
        await axios.post(`${API}/add/history`, body)
            .then(res => {
                // console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {

        return (
            <>
                <br />
                <Row justify="center">
                    <Col span={8}>
                        <Card>
                            <h1>Ruay-M SIGN IN</h1>
                            <br />
                            <Form onSubmitCapture={this.login.bind(this)}>
                                <Form.Item
                                    label="USERNAME : "
                                    name="username"
                                    rules={[{ required: true, message: 'กรุณากรอก username!' }]}
                                >
                                    <Input size="large" prefix={<UserOutlined />} onChange={(e) => this.setState({ ipt_username: e.target.value })} />
                                </Form.Item>

                                <Form.Item
                                    label="PASSWORD : "
                                    name="password"
                                    rules={[{ required: true, message: 'กรุณากรอก password!' }]}
                                >
                                    <Input.Password htmlType="passwrod" size="large" prefix={<LockOutlined />} onChange={(e) => this.setState({ ipt_password: e.target.value })} />
                                </Form.Item>
                                
                                <ReCAPTCHA
                                sitekey="6LeutskeAAAAALyr1OxzpjHCXHxfAjPVPCvjyhKy"
                                onChange={(e) => this.setState({ reCaptcha: e })}
                                />
                                <br/>

                                <Form.Item >
                                    <Button type="primary" htmlType="submit" block shape="round" size="large">Sing In</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(login)