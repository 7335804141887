import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import History from './history';
import Manage from './manage';
import Display from './display';

import Hanoi from './stocks/hanoi';
import Lao from './stocks/lao';
import Dowjones from './stocks/dowjones';
import Malaysia from './stocks/malaysia';
import Siam from './stocks/siam';
import States from './stocks/states';

// หวยอื่นๆ
import StockAward1 from './stocksOther/award1';
import StockAward2 from './stocksOther/award2';

import {
  Route, Link
} from "react-router-dom";
import { Layout, Menu, Row, Button, Col, Drawer, Divider, Image } from 'antd';
import {
  HistoryOutlined,
  PieChartFilled,
  LogoutOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import '../css/home.css';
import { API } from '../util/config';

const { Header, Content, Footer, Sider } = Layout;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      visible: false,
    };
  }

  componentDidMount() {
    this.getUser()
  }

  async getUser() {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      this.setState({ user: user, accessToken: user.accessToken }, () => {

        this.myInterval = setInterval(() => {
          this.getDataUser()
        }, 30000);
        
      })

    } else {
      this.logout()    
      // console.log('logout')
    }
  }

  logout() {
    clearInterval(this.myInterval);
    localStorage.clear();
    this.props.history.push("/login");
  }

  async getDataUser() {
    await axios.get(`${API}/user`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
        .then(res => {
            const data = res.data
            if (!data.status) {
              this.logout()
            }
        })
        .catch(err => {
          console.log(err)
          this.logout()
        })
  }

  render() {
    return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsed={true}>
            <div className="logo" style={{ height: 32, margin: 16 }} >
              <h5 style={{ color: 'white' }}>RUAY</h5>
            </div>
            <Menu theme="dark" defaultSelectedKeys={['2']} mode="inline">
              {/* <Menu.Item key="1" icon={<HistoryOutlined />}>
                <Link to="/manage/history">ประวัติการใช้งาน</Link>
              </Menu.Item> */}
              <Menu.Item key="2" icon={<PieChartFilled />}>
                <Link to="/manage/">จัดการหวย</Link>
              </Menu.Item>
              {/* <Menu.Item key="3" icon={<MessageOutlined />} onClick={() => this.setState({ visible: true })}>
                  แจ้งเตือนผ่านไลน์
                </Menu.Item> */}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }} >
              <Row>
                <Col span={12} style={{ paddingLeft: 30, fontSize: 20 }}>
                  {/* <Avatar src="https://thumbs.dreamstime.com/b/happy-smiling-geek-hipster-beard-man-cool-avatar-geek-man-avatar-104871313.jpg" size="large" />
                    <span style={{ marginLeft: 15 }}>{this.state.user !== [] ? this.state.user.name : ''}</span> */}
                </Col>
                <Col span={12} style={{ textAlign: 'right', paddingRight: 30 }}>
                  <Button type="link" onClick={() => this.logout()} icon={<LogoutOutlined />}>ออกจากระบบ</Button>
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: '0 16px' }}>

              <Route exact path="/manage/">
                <Manage />
              </Route>

              {/* <Route exact path="/manage/stocksother">
                <Shares />
              </Route> */}
              <Route exact path="/manage/stocksother/award1">
                <StockAward1 />
              </Route>
              <Route exact path="/manage/stocksother/award2">
                <StockAward2 />
              </Route>

              <Route exact path="/manage/history">
                <History />
              </Route>
              <Route exact path="/manage/display/:stocks"
                render={(props) => (
                  <Display {...props} />
                )}
              />
              <Route exact path="/manage/stocks/hanoi/:id"
                render={(props) => (
                  <Hanoi {...props} />
                )}
              />
              <Route exact path="/manage/stocks/lao/:id"
                render={(props) => (
                  <Lao {...props} />
                )}
              />
              <Route exact path="/manage/stocks/dowjones/:id"
                render={(props) => (
                  <Dowjones {...props} />
                )}
              />
              <Route exact path="/manage/stocks/malaysia/:id"
                render={(props) => (
                  <Malaysia {...props} />
                )}
              />
              <Route exact path="/manage/stocks/siam/:id"
                render={(props) => (
                  <Siam {...props} />
                )}
              />
              <Route exact path="/manage/stocks/states/:id"
                render={(props) => (
                  <States {...props} />
                )}
              />
            </Content>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
          </Layout>
        </Layout>

        <Drawer
          width={500}
          placement="right"
          closable={false}
          onClose={() => this.setState({ visible: false })}
          visible={this.state.visible}
        >
          <h5 style={{ marginBottom: 24 }}>แจ้งเตือนผ่านไลน์</h5>
          <Divider />
          <Row>
            <Col span={12}>
              <DescriptionItem title="กลุ่มไลน์" content="ออกผล" />
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>

            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <Image
                width={200}
                src="https://qr-official.line.me/sid/M/680wxrbt.png"
              />
            </Col>
          </Row>

        </Drawer>
      </>
    );
  }
}

export default withRouter(home)



