export function formateDateTH(value) {
    const d = new Date(value);
    const ye = new Intl.DateTimeFormat('th', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('th', { month: 'long' }).format(d);
    const da = new Intl.DateTimeFormat('th', { day: '2-digit' }).format(d);
    const date = `${da} ${mo} ${ye}`

    return date
}

export function Tomorrow() {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(tomorrow);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(tomorrow);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(tomorrow);
    const date = `${ye}-${mo}-${da}`

    return date
}

export function Today() {
    const today = new Date()
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(today);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today);
    const date = `${ye}-${mo}-${da}`

    return date
}

export function DateTimeToday() {
    const today = new Date()
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(today);
    const da = new Intl.DateTimeFormat('th', { day: '2-digit' }).format(today);
    const time = new Intl.DateTimeFormat('th', { timeStyle: 'medium' }).format(today);
    const date = `${ye}-${mo}-${da} ${time}`

    return date
}