// หวยหุ้น (ก้อง)
import React, { Component } from 'react';
import { Table, Button, Space } from 'antd';
import {
    EditFilled
} from '@ant-design/icons';
import { API } from "../../util/config";
import axios from 'axios';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class manage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessToken: '',
            columns: [
                {
                    title: 'ลำดับที่',
                    dataIndex: 'lotteryTimeId',
                },
                {
                    title: 'ชื่อหวย',
                    dataIndex: 'lotteryName',
                    render: (text, record) => (
                        <p>{text} ({record.lotterTimeName})</p>
                    ),
                },
                {
                    title: 'วันที่ออกผล',
                    dataIndex: 'lotteryDateTimeResult',
                },
                {
                    title: '',
                    dataIndex: '',
                    render: (text, record) => (
                        <Space size="middle">
                            <Button type="primary" onClick={(e) => this.goAward(record)}><EditFilled /> จัดการตัวเลข</Button>
                        </Space>
                    ),
                },
            ],
            data: [
            ]
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken }, () => {
                this.getData()
            })
        }
    }

    async getData() {
        await axios.get(`${API}/stocks/other/share/all`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    // console.log(data)
                    this.setState({ data: data.result })
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    async goAward(data) {
        localStorage.setItem('dataAward', JSON.stringify({ type: 'share', id: data.lotteryTimeId, name: data.lotteryName, award: [data.lotteryResultThree, data.loterryResultTwo], date: data.lotteryDate, dateTime: data.lotteryDateTimeResult }))
        this.props.history.push("/manage/stocksother/award2");
    }

    render() {
        return (
            <>
                <Table columns={this.state.columns} dataSource={this.state.data} bordered />
            </>
        );
    }
}

export default withRouter(manage)