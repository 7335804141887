import React, { Component } from 'react';
import { Breadcrumb, Divider, Table, message } from 'antd';
import { API } from "../util/config";
import axios from 'axios';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class history extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyList: [],
            accessToken: '',
            pagination: {
                current: 1,
                pageSize: 20,
            },
            columns: [
                {
                    title: 'ลำดับที่',
                    dataIndex: 'order',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.order - b.order,
                },
                {
                    title: 'IP Address',
                    dataIndex: 'history_ipaddress',
                },
                {
                    title: 'ผู้ดำเนินการ',
                    dataIndex: 'history_user',
                },
                {
                    title: 'เหตุการณ์',
                    dataIndex: 'history_event',
                },
                {
                    title: 'วันเวลา',
                    dataIndex: 'created_at',
                },
            ],
            data: []
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken }, () => this.getDataHistory())
        }
    }

    // ดึงข้อมูลประวัติการใช้งาน
    async getDataHistory() {
        await axios.get(`${API}/history`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
        .then(res => {
            const data = res.data
            if (data.status) {
                this.setState({ historyList: data.result })
                var list = []
                data.result.forEach((item, index) => {
                    list.push(
                        {
                            key: index + 1,
                            order: index + 1,
                            ...item
                        }
                    )
                });
                this.setState({ data: list })
            } else if (!data.session) {
                message.error(data.message)
                this.props.history.push("/login");
            } else {
                console.log(data.message)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>ประวัติการใช้งาน</Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Divider orientation="left"><h3>ประวัติการใช้งาน</h3></Divider>
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={this.state.pagination} bordered />
            </>
        );
    }
}

export default withRouter(history)