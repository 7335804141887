import React, { Component } from 'react';
import { Breadcrumb, Input, Button, Row, Col, Form, message } from 'antd';
import {
    RollbackOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { API } from "../../util/config";
import axios from 'axios';
import { formateDateTH } from '../../util/formatDate'
import x0p from 'x0popup';
import '../../App.css';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class dowjones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            award_dowjones_1st: '', award_dowjones_2nd: '',
            data: {},
            accessToken: '',
            stocks_dateTime: '',
            stocks_id: '',
            award_date: ''
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken })
            this.getData(user.accessToken)
        }
    }

    async getData(accessToken) {
        const id = this.props.match.params.id
        await axios.get(`${API}/dowjones/id/${id}`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    // console.log(data)
                    this.setState({
                        data: data.result,
                        stocks_dateTime: data.result.award_date + " " + data.result.stocks_time,
                        award_date: data.result.award_date,
                        stocks_id: data.result.stocks_id,
                        award_dowjones_1st: data.result.award_dowjones_1st,
                        award_dowjones_2nd: data.result.award_dowjones_2nd
                    })

                } else if (!data.session) {
                    message.error(data.message)
                    this.props.history.push("/login");
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    checkTime() {
        // console.log("stocks_dateTime : ", this.state.stocks_dateTime)
        // console.log("DateTimeToday : ", DateTimeToday())
        // if(DateTimeToday() < this.state.stocks_dateTime) {
        this.updateData()
        // } else {
        //     message.error('เลขจะออกแล้ว แก้ไม่ได้นะครับ!!');
        // } 
    }

    async updateData() {
        const ipt = this.state
        if (ipt.award_dowjones_1st !== "" && ipt.award_dowjones_2nd !== "") {
            x0p({
                title: 'ยืนยัน!!',
                text: ``,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        id: this.state.stocks_id,
                        date: this.state.award_date,
                        a1: ipt.award_dowjones_1st, a2: this.state.award_dowjones_2nd
                    }
                    // console.log(body)
                    await axios.post(`${API}/dowjones/update`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
                        .then(res => {
                            const data = res.data
                            if (data.status) {
                                x0p('บันทึกข้อมูลเสร็จสิ้น!!', null, 'ok', false);
                                const user = JSON.parse(localStorage.getItem('user'))
                                this.setLogHistory(user.user.name, `${data.result.stocks_name} บน: ${data.result.award_dowjones_1st} ล่าง: ${data.result.award_dowjones_2nd}`)
                            } else {
                                console.log(data)
                                x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            x0p('การเชื่อมต่อผิดพลาด dowjones/update', null, 'error', false);
                        })
                }
            })
        } else {
            message.error("กรุณากรอกข้อมูลให้ครบ!!")
        }
    }

    async setLogHistory(user, event) {
        const body = {
            ip: localStorage.getItem('ip'),
            user: user,
            event: event
        }
        await axios.post(`${API}/add/history`, body)
            .then(res => {
                // console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                    <Breadcrumb.Item>ออกรางวัล</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการตัวเลข</Breadcrumb.Item>
                </Breadcrumb>

                <br />

                <div style={{ minHeight: 280, padding: 24, marginBottom: 40, background: '#fff' }}>
                    <Form onSubmitCapture={() => this.checkTime()}>
                        <Row justify="end">
                            <Col span={24}>
                                <h3>งวดหวยวันที่ {this.state.data.award_date ? formateDateTH(this.state.data.award_date) : ''} - {this.state.data.stocks_name ? this.state.data.stocks_name : ''}</h3>
                                <p class="text-muted" style={{ marginTop: -10 }}>ตั้งค่าตัวเลขของงวดนี้</p>
                            </Col>

                            <Button shape="round" size="large" style={{ marginBottom: 20, marginRight: 10 }} onClick={() => this.props.history.goBack()} icon={<RollbackOutlined />}>ย้อนกลับ</Button>

                            <Button type="primary" htmlType="submit" shape="round" size="large" style={{ marginBottom: 20 }} icon={<SaveOutlined />}>บันทึก</Button>
                        </Row>

                        <div class="text-center text-light">
                            {/* a1 */}
                            <Row className="input-list" style={{ backgroundColor: '#C70039' }}>
                                <Col span={24}>
                                    <h5 class="text-light">เลขบน</h5>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="5" maxlength="5" size="large"
                                        value={this.state.award_dowjones_1st}
                                        onChange={(e) => this.setState({ award_dowjones_1st: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            {/* a2 */}
                            <Row className="input-list" style={{ backgroundColor: '#FF5733' }}>
                                <Col span={24}>
                                    <h5 class="text-light">เลขล่าง</h5>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="5" maxlength="5" size="large"
                                        value={this.state.award_dowjones_2nd}
                                        onChange={(e) => this.setState({ award_dowjones_2nd: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

export default withRouter(dowjones)