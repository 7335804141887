import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Login from './components/login';
import Home from './components/home';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/login" render={(props) => (
            <Login />
          )}
          />

          <Route exact path="/">
            <Redirect to="/manage" />
          </Route>
          <Route path="/manage" render={(props) => (
            <Home />
          )}
          />
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;