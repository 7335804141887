import React, { Component } from 'react';
import { Breadcrumb, Input, Button, Row, Col, message, Form } from 'antd';
import {
    RollbackOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import '../../App.css';
import { API } from "../../util/config";
import axios from 'axios';
import x0p from 'x0popup';
import { formateDateTH, DateTimeToday } from '../../util/formatDate'
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class lao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ipt_a1: '',
            data: {},
            accessToken: '',
            stocks_dateTime: '',
            award_date: ''
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken })
            this.getData(user.accessToken)
        }
    }

    async getData(accessToken) {
        const id = this.props.match.params.id
        await axios.get(`${API}/lao/id/${id}`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.setState({
                        data: data.result,
                        stocks_id: data.result.stocks_id,
                        stocks_dateTime: data.result.award_date + " " + data.result.stocks_time,
                        award_date: data.result.award_date,
                        ipt_a1: data.result.award_lao_1,
                    })
                } else if (!data.session) {
                    message.error(data.message)
                    this.props.history.push("/login");
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    checkTime(){
        // console.log("stocks_dateTime : ", this.state.stocks_dateTime)
        // console.log("DateTimeToday : ", DateTimeToday())
        // if(DateTimeToday() < this.state.stocks_dateTime) {
            this.updateData()   
        // } else {
        //     message.error('เลขจะออกแล้ว แก้ไม่ได้นะครับ!!');
        // } 
    }

    async updateData() {
        if (this.state.ipt_a1 !== "") {
            x0p({
                title: 'ยืนยัน!!',
                text: ``,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        id: this.state.stocks_id,
                        a1: this.state.ipt_a1,
                        date: this.state.award_date
                    }
                    await axios.post(`${API}/lao/update`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
                        .then(res => {
                            const data = res.data
                            if (data.status) {
                                const user = JSON.parse(localStorage.getItem('user'))
                                this.setLogHistory(user.user.name, `${data.result.stocks_name} ออกผล : ${data.result.award_lao_1}`)
                                x0p('บันทึกข้อมูลเสร็จสิ้น!!', null, 'ok', false);
                                
                            } else {
                                console.log(data.message)
                                x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                            }
                        })
                        .catch(err => {
                            x0p('การเชื่อมต่อผิดพลาด lao/update', null, 'error', false);
                        })
                }
            })
        }
    }

    async setLogHistory(user, event){
        const body = {
            ip: localStorage.getItem('ip'),
            user: user,
            event: event
        }
        await axios.post(`${API}/add/history`, body)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                    <Breadcrumb.Item>ออกรางวัล</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการตัวเลข</Breadcrumb.Item>
                </Breadcrumb>

                <br />
                <div style={{ minHeight: 280, padding: 24, marginBottom: 40, background: '#fff' }}>
                    <Form onSubmitCapture={() => this.checkTime()}>
                        <Row justify="end">
                            <Col span={24}>
                                <h3>
                                    งวดหวยวันที่ {this.state.data.award_date ? formateDateTH(this.state.data.award_date) : ''} - {this.state.data.stocks_name ? this.state.data.stocks_name : ''}
                                </h3>
                                <p class="text-muted" style={{ marginTop: -10 }}>ตั้งค่าตัวเลขของงวดนี้</p>
                            </Col>

                            <Button shape="round" size="large" style={{ marginBottom: 20, marginRight: 10 }} onClick={() => this.props.history.goBack()} icon={<RollbackOutlined />}>ย้อนกลับ</Button>
                            <Button type="primary" htmlType="submit" shape="round" size="large" style={{ marginBottom: 20 }} icon={<SaveOutlined />} >บันทึก</Button>
                        </Row>

                        <div class="text-center text-light">
                            <Row style={{ backgroundColor: '#C70039', paddingTop: 15, paddingBottom: 5, marginBottom: 5 }}>
                                <Col span={24}>
                                    <Input
                                        type="text" pattern="\d*" minLength="5" maxlength="5" size="large" style={{ width: 300, letterSpacing: 20, padding: 20, textAlign: 'center', fontSize: 20, fontWeight: 'bolder' }}
                                        value={this.state.ipt_a1}
                                        onChange={(e) => this.setState({ ipt_a1: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

export default withRouter(lao)