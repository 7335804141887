import React, { Component } from 'react';
import { Breadcrumb, Input, Button, Row, Col, Form, message } from 'antd';
import {
    RollbackOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { API } from "../../util/config";
import axios from 'axios';
import { formateDateTH, DateTimeToday } from '../../util/formatDate'
import '../../App.css';
import x0p from 'x0popup';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom'

export default class siam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ipt_a1Top: '',
            ipt_a21: '', ipt_a22: '',
            ipt_a31: '', ipt_a32: '', ipt_a33: '',
            ipt_a41: '', ipt_a42: '', 
            ipt_a51: '', ipt_a52: '',
            stocks_id: '', 
            data: {},
            accessToken: '',
            stocks_dateTime: '',
            award_date: '',
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken })
            this.getData(user.accessToken)
        }   
    }

    async getData(accessToken) {
        const id = this.props.match.params.id
        // console.log(accessToken)
        // console.log(`${API}/siam/id/${id}`)
        await axios.get(`${API}/siam/id/${id}`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
            .then(res => {
                const data = res.data
                // console.log(data)
                if (data.status) {
                    // console.log(data)
                    this.setState({
                        data: data.result,
                        stocks_id: data.result.stocks_id,
                        stocks_dateTime: data.result.award_date + " " + data.result.stocks_time,
                        award_date: data.result.award_date,
                        ipt_a1Top: data.result.award_siam_1Top,
                        ipt_a1Down: data.result.award_siam_1Down,
                        ipt_a21: data.result.award_siam_2_1,
                        ipt_a22: data.result.award_siam_2_2,
                        ipt_a31: data.result.award_siam_3_1,
                        ipt_a32: data.result.award_siam_3_2,
                        ipt_a33: data.result.award_siam_3_3,
                        ipt_a41: data.result.award_siam_4_1,
                        ipt_a42: data.result.award_siam_4_2,
                        ipt_a51: data.result.award_siam_5_1,
                        ipt_a52: data.result.award_siam_5_2,
                    })

                // } else if (!data.session) {
                //     message.error(data.message)
                //     this.props.history.push("/login");
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    checkTime(){
        // console.log("stocks_dateTime : ", this.state.stocks_dateTime)
        // console.log("DateTimeToday : ", DateTimeToday())
        // if(DateTimeToday() < this.state.stocks_dateTime) {
            this.updateData()   
        // } else {
        //     message.error('เลขจะออกแล้ว แก้ไม่ได้นะครับ!!');
        // } 
    }

    async updateData() {
        const ipt = this.state
        if(
            ipt.ipt_a1Top !== "" &&
            ipt.ipt_a1Down !== "" &&
            ipt.ipt_a21 !== "" && ipt.ipt_a22 !== "" &&
            ipt.ipt_a31 !== "" && ipt.ipt_a32 !== "" && ipt.ipt_a33 !== "" &&
            ipt.ipt_a41 !== "" && ipt.ipt_a42 !== "" && 
            ipt.ipt_a51 !== "" && ipt.ipt_a52 !== ""
        ){
            x0p({
                title: 'ยืนยัน!!',
                text: ``,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        id: this.state.stocks_id,
                        date: this.state.award_date,
                        a1: ipt.ipt_a1Top,
                        a2: ipt.ipt_a1Down,
                        // a21: ipt.ipt_a21, a22: ipt.ipt_a22,
                        // a31: ipt.ipt_a31, a32: ipt.ipt_a32, a33: ipt.ipt_a33, 
                        // a41: ipt.ipt_a41, a42: ipt.ipt_a42, 
                        // a51: ipt.ipt_a51, a52: ipt.ipt_a52
                    }
                    await axios.post(`${API}/siam/update`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
                        .then(res => {
                            const data = res.data
                            if (data.status) {
                                x0p('บันทึกข้อมูลเสร็จสิ้น!!', null, 'ok', false);
                                const user = JSON.parse(localStorage.getItem('user'))
                                this.setLogHistory(user.user.name, `${data.result.stocks_name}\nบน: ${data.result.award_siam_1Top}\nล่าง: ${data.result.award_siam_1Down}`)
                            } else {
                                console.log(data.message)
                                x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            x0p('การเชื่อมต่อผิดพลาด siam/update', null, 'error', false);
                        })
                }
            })
        } else {
            message.error("กรุณากรอกข้อมูลให้ครบ!!")
        }
    }

    async setLogHistory(user, event){
        const body = {
            ip: localStorage.getItem('ip'),
            user: user,
            event: event
        }
        await axios.post(`${API}/add/history`, body)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                    <Breadcrumb.Item>ออกรางวัล</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการตัวเลข</Breadcrumb.Item>
                </Breadcrumb>

                <br />

                <div style={{ minHeight: 280, padding: 24, marginBottom: 40, background: '#fff' }}>
                    <Form onSubmitCapture={() => this.checkTime()}>
                        <Row justify="end">
                            <Col span={24}>
                                <h3>งวดหวยวันที่ {this.state.data.award_date ? formateDateTH(this.state.data.award_date) : ''} - {this.state.data.stocks_name ? this.state.data.stocks_name : ''}</h3>
                                <p class="text-muted" style={{ marginTop: -10 }}>ตั้งค่าตัวเลขของงวดนี้</p>
                            </Col>
                            <Button shape="round" size="large" style={{ marginBottom: 20, marginRight: 10 }} onClick={() => this.props.history.goBack()} icon={<RollbackOutlined />}>ย้อนกลับ</Button>
                            <Button type="primary" htmlType="submit" shape="round" size="large" style={{ marginBottom: 20 }} icon={<SaveOutlined />} >บันทึก</Button>
                        </Row>

                        <div class="text-center text-light">
                            {/* a1Top */}
                            <Row className="input-list" style={{ backgroundColor: '#C70039' }}>
                                <Col span={24}> 
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6"  size="large"
                                        value={this.state.ipt_a1Top}
                                        onChange={(e) => this.setState({ ipt_a1Top: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            {/* a1Down */}
                            <Row className="input-list" style={{ backgroundColor: '#C70039' }}>
                                <Col span={24}> 
                                    <Input className="input-number" type="text" pattern="\d*" minLength="2" maxlength="2"  size="large"
                                        value={this.state.ipt_a1Down}
                                        onChange={(e) => this.setState({ ipt_a1Down: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            {/* a2 */}
                            <Row className="input-list" style={{ backgroundColor: '#424949' }}>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a21}
                                        onChange={(e) => this.setState({ ipt_a21: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a22}
                                        onChange={(e) => this.setState({ ipt_a22: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            {/* a3 */}
                            <Row className="input-list" style={{ backgroundColor: '#566573' }}>
                                <Col span={8}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a31}
                                        onChange={(e) => this.setState({ ipt_a31: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={8}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a32}
                                        onChange={(e) => this.setState({ ipt_a32: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={8}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a33}
                                        onChange={(e) => this.setState({ ipt_a33: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            {/* a4 */}
                            <Row className="input-list" style={{ backgroundColor: '#424949' }}>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a41}
                                        onChange={(e) => this.setState({ ipt_a41: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a42}
                                        onChange={(e) => this.setState({ ipt_a42: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            {/* a5 */}
                            <Row className="input-list" style={{ backgroundColor: '#566573' }}>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a51}
                                        onChange={(e) => this.setState({ ipt_a51: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="6" maxlength="6" size="large" 
                                        value={this.state.ipt_a52}
                                        onChange={(e) => this.setState({ ipt_a52: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </>
        )
    }
}
