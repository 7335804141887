// ออกผลจำนวน 1 รางวัล
import React, { Component } from 'react';
import { Breadcrumb, Input, Button, Row, Col, message, Form } from 'antd';
import {
    RollbackOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import '../../App.css';
import { API } from "../../util/config";
import axios from 'axios';
import x0p from 'x0popup';
import { formateDateTH } from '../../util/formatDate'
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class lao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ipt_a1: '',
            ipt_a2: '',
            data: {},
            accessToken: '',
            stocks_id: '',
            stocks_date: '',
            stocks_dateTime: '',
            stocks_name: '',
            stocks_type: ''
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken })
            this.getData()
        }
    }

    async getData() {
        const dataAward = JSON.parse(localStorage.getItem('dataAward'))
        this.setState({ stocks_id: dataAward.id, stocks_name: dataAward.name, stocks_dateTime: dataAward.dateTime, stocks_date: dataAward.date, ipt_a1: dataAward.award[0], ipt_a2: dataAward.award[1], stocks_type: dataAward.type })
    }

    async updateData() {
        if (this.state.ipt_a1 !== "" && this.state.ipt_a2 !== "") {
            x0p({
                title: 'ยืนยัน!!',
                text: ``,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        id: this.state.stocks_id,
                        a1: this.state.ipt_a1,
                        a2: this.state.ipt_a2,
                        date: this.state.stocks_date,
                        lotto_name: this.state.stocks_name
                    }
                    await axios.post(`${API}/stocks/other/${this.state.stocks_type}/update`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
                        .then(res => {
                            const data = res.data
                            if (data.status) {
                                const user = JSON.parse(localStorage.getItem('user'))
                                this.setLogHistory(user.user.name, `${this.state.stocks_name} บน : ${this.state.ipt_a1} ล่าง : ${this.state.ipt_a2}`)
                                x0p('บันทึกข้อมูลเสร็จสิ้น!!', null, 'ok', false);
                            } else {
                                console.log(data.message)
                                x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            x0p(`การเชื่อมต่อผิดพลาด stocks/other/${this.state.stocks_type}/update`, null, 'error', false);
                        })
                }
            })
        } else {
            message.error("กรุณากรอกข้อมูลให้ครบ!!")
        }
    }

    async setLogHistory(user, event) {
        const body = {
            ip: localStorage.getItem('ip'),
            user: user,
            event: event
        }
        await axios.post(`${API}/add/history`, body)
            .then(res => {
                // console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                    <Breadcrumb.Item>ออกรางวัล</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการตัวเลข</Breadcrumb.Item>
                </Breadcrumb>

                <br />
                <div style={{ minHeight: 280, padding: 24, marginBottom: 40, background: '#fff' }}>
                    <Form onSubmitCapture={() => this.updateData()}>
                        <Row justify="end">
                            <Col span={24}>
                                <h3>
                                    งวดหวยวันที่ {this.state.data.award_date ? formateDateTH(this.state.data.award_date) : ''} - {this.state.stocks_name ? this.state.stocks_name : ''}
                                </h3>
                                <p class="text-muted" style={{ marginTop: -10 }}>ตั้งค่าตัวเลขของงวดนี้</p>
                            </Col>

                            <Button shape="round" size="large" style={{ marginBottom: 20, marginRight: 10 }} onClick={() => this.props.history.goBack()} icon={<RollbackOutlined />}>ย้อนกลับ</Button>
                            <Button type="primary" htmlType="submit" shape="round" size="large" style={{ marginBottom: 20 }} icon={<SaveOutlined />}>บันทึก</Button>
                        </Row>

                        <div class="text-center text-light">
                            <Row style={{ backgroundColor: '#C70039', paddingTop: 15, paddingBottom: 5, marginBottom: 5 }}>
                                <Col span={24}>
                                    <h5 class="text-light">เลขบน</h5>
                                    <Input
                                        type="text" pattern="\d*" minLength="3" maxlength="3" size="large" style={{ width: 300, letterSpacing: 20, padding: 20, textAlign: 'center', fontSize: 20, fontWeight: 'bolder' }}
                                        value={this.state.ipt_a1}
                                        onChange={(e) => this.setState({ ipt_a1: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                            <Row style={{ backgroundColor: '#FF5733', paddingTop: 15, paddingBottom: 5, marginBottom: 5 }}>
                                <Col span={24}>
                                    <h5 class="text-light">เลขล่าง</h5>
                                    <Input
                                        type="text" pattern="\d*" minLength="2" maxlength="2" size="large" style={{ width: 300, letterSpacing: 20, padding: 20, textAlign: 'center', fontSize: 20, fontWeight: 'bolder' }}
                                        value={this.state.ipt_a2}
                                        onChange={(e) => this.setState({ ipt_a2: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

export default withRouter(lao)