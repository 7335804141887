import React, { Component } from 'react';
import { Breadcrumb, Divider, Table, Button, Space, Modal, Input, Select, Form, message } from 'antd';
import {
    // EditOutlined,
    // DeleteOutlined,
    DesktopOutlined,
    // PlusCircleFilled
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { API } from "../util/config";
import axios from 'axios';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

// หวยอื่น (ก้อง)
import Shares from './listOther/shares';
import Laos from './listOther/laos';

const { Option } = Select;
const key = 'updatable';

class manage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalAddVisible: false,
            isModalEditVisible: false,
            isModalDeleteVisible: false,
            typeList: [],
            stocksList: [],
            slt_add_type: '',
            ipt_add_name: '',
            ipt_edit_name: '',
            ipt_edit_id: '',
            ipt_delete_id: '',
            ipt_delete_username: '',
            ipt_delete_password: '',
            accessToken: '',
            user_id: '',
            columns: [
                {
                    title: 'ลำดับที่',
                    dataIndex: 'stocks_id',
                },
                {
                    title: 'ประเภทหวย',
                    dataIndex: 'type_name',
                },
                {
                    title: 'ชื่อหวย',
                    dataIndex: 'stocks_name',
                },
                {
                    title: 'ตั้งค่า',
                    dataIndex: 'setting',
                    render: (text, record) => (
                        <Space size="middle">
                            {/* <a onClick={() =>  this.setState({ isModalEditVisible: true, ipt_edit_id: record.stocks_id, ipt_edit_name: record.stocks_name })}><EditOutlined /></a>
                            <a onClick={() => this.setState({ isModalDeleteVisible: true, ipt_delete_id: record.stocks_id })}><DeleteOutlined /></a> */}
                            <Link to={`/manage/display/${record.stocks_id}`}><Button type="primary"><DesktopOutlined /> ออกรางวัล</Button></Link>
                        </Space>
                    ),
                },
            ],
            data: [
            ]
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken, user_id: user.user.id },() => {
                this.getDataType()
                this.typeList()
                this.getDataStocks()
            })
        }
    }

    // ดึงข้อมูลหวย
    async getDataStocks() {
        await axios.get(`${API}/stocks/all`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                // console.log(data)
                if (data.status) {
                    this.setState({ stocksList: data.result })
                    var list = []
                    data.result.forEach((item, index) => {
                        list.push(
                            {
                                key: index + 1,
                                ...item
                            }
                        )
                    });
                    this.setState({ data: list })
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // ดึงข้อมูลประเภทหวย
    async getDataType() {
        await axios.get(`${API}/type/all`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.setState({ typeList: data.result })
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // STOCKS ADD
    async stocksAdd() {
        const body = {
            type: this.state.slt_add_type,
            name: this.state.ipt_add_name
        }
        await axios.post(`${API}/stocks/add`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.getDataStocks()
                    setTimeout(() => {
                        message.success({ content: 'ทำรายการเสร็จสิ้น!', key, duration: 2 })
                        this.setState({ isModalAddVisible: false })
                    }, 1000);
                } else {
                    console.log(data.message)
                    setTimeout(() => {
                        message.error('ทำรายการไม่สำเร็จ!!');
                        this.setState({ isModalAddVisible: false })
                    }, 1000);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // STOCKS EDIT
    async stocksEdit(id, name) {
        const body = {
            id: id,
            name: name
        }
        await axios.post(`${API}/stocks/edit`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.getDataStocks()
                    setTimeout(() => {
                        message.success({ content: 'ทำรายการเสร็จสิ้น!', key, duration: 2 })
                        this.setState({ isModalEditVisible: false })
                    }, 1000);
                } else {
                    console.log(data.message)
                    setTimeout(() => {
                        message.error('ทำรายการไม่สำเร็จ!!');
                        this.setState({ isModalEditVisible: false })
                    }, 1000);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // STOCKS DELETE
    async stocksDelete(id) {
        const body = {
            id: id
        }
        await axios.post(`${API}/stocks/delete`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                // console.log(data)
                if (data.status) {
                    this.getDataStocks()
                    setTimeout(() => {
                        message.success({ content: 'ทำรายการเสร็จสิ้น!', key, duration: 2 })
                        this.setState({ isModalDeleteVisible: false })
                    }, 1000);
                } else {
                    console.log(data.message)
                    setTimeout(() => {
                        message.error('ทำรายการไม่สำเร็จ!!');
                        this.setState({ isModalDeleteVisible: false })
                    }, 1000);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    typeList() {
        const typeList = this.state.typeList
        let list = typeList.map((item, index) =>
            <Option key={index} value={item.type_id}>{item.type_name}</Option>
        );
        return (list)
    }

    //  modal Add
    handleOkAdd = async () => {
        if (this.state.slt_add_type !== '' && this.state.ipt_add_name !== '') {
            message.loading({ content: 'Loading...', key });
            this.stocksAdd(this.state.slt_add_type, this.state.ipt_add_name)
        } else {
            message.error('กรุณากรอกข้อมูลให้ถูกต้อง');
        }
    };

    //  modal Edit
    showModalEdit = (id, name) => {
        this.setState({ isModalEditVisible: true, ipt_edit_id: id, ipt_edit_name: name })
    };

    handleOkEdit = () => {
        if (this.state.ipt_edit_name !== '' && this.state.ipt_edit_id !== '') {
            message.loading({ content: 'Loading...', key });
            this.stocksEdit(this.state.ipt_edit_id, this.state.ipt_edit_name)
        } else {
            message.error('กรุณากรอกข้อมูลให้ถูกต้อง');
        }
    };

    //  modal Delete
    showModalDelete = (id) => {
        // console.log(id)
        this.setState({ isModalDeleteVisible: true, ipt_delete_id: id })
    };

    handleOkDelete = () => {
        if (this.state.ipt_delete_id !== '') {
            message.loading({ content: 'Loading...', key });
            this.stocksDelete(this.state.ipt_delete_id)
        } else {
            message.error('กรุณากรอกข้อมูลให้ถูกต้อง');
        }
    };

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                </Breadcrumb>

                <br />

                <Divider orientation="left"><h3>รายการ</h3></Divider>

                {/* <Row justify="end">
                    <Button type="primary" icon={<PlusCircleFilled />} shape="round" size="large" style={{ marginBottom: 20 }}
                        onClick={() => this.setState({ isModalAddVisible: true })} >
                        เพิ่มหวย
                    </Button>
                </Row> */}

                <Table columns={this.state.columns} dataSource={this.state.stocksList} bordered />

                {/* หวยอื่นๆ (ก้อง)*/}
                {this.state.user_id < 10 ?
                    <>
                        <br/>
                        <br/>
                        <h3>NEWหวยหุ้น</h3>
                        <Shares />
                        <br/>

                        <br/>
                        <h3>NEWหวยลาว</h3>
                        <Laos />
                    </>
                    : null
                }

                {/* modal add */}
                <Modal title="เพิ่ม" visible={this.state.isModalAddVisible} onOk={this.handleOkAdd.bind(this)}
                    onCancel={() => this.setState({ isModalAddVisible: false })} cancelText="ยกเลิก" okText="ตกลง"
                >
                    <Form.Item>
                        <p>ประเภทหวย</p>
                        <Select placeholder="เลือกประเภทหวย" onChange={value => this.setState({ slt_add_type: value })}>
                            {this.typeList()}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <p>ชื่อหวย</p>
                        <Input placeholder="กรอกชื่อหวย" onChange={e => this.setState({ ipt_add_name: e.target.value })} />
                    </Form.Item>
                </Modal>

                {/* modal edit */}
                <Modal title="แก้ไข" visible={this.state.isModalEditVisible} onOk={this.handleOkEdit.bind(this,)}
                    onCancel={() => this.setState({ isModalEditVisible: false })} cancelText="ยกเลิก" okText="ตกลง"
                >
                    <Form.Item>
                        <p>ชื่อหวย</p>
                        <Input placeholder="กรอกชื่อหวย" defaultValue={this.state.ipt_edit_name} onChange={e => this.setState({ ipt_edit_name: e.target.value })} />
                    </Form.Item>
                </Modal>

                {/* modal delete */}
                <Modal title="ลบ" visible={this.state.isModalDeleteVisible} onOk={this.handleOkDelete.bind(this)}
                    onCancel={() => this.setState({ isModalDeleteVisible: false })} cancelText="ยกเลิก" okText="ตกลง"
                >
                    <p>ต้องการลบข้อมูลใช่หรือไม่??...</p>
                    <Form.Item>
                        <p>username</p>
                        <Input placeholder="username" defaultValue="" onChange={e => this.setState({ ipt_delete_username: e.target.value })} />
                    </Form.Item>
                    <Form.Item>
                        <p>password</p>
                        <Input placeholder="password" defaultValue="" onChange={e => this.setState({ ipt_delete_password: e.target.value })} />
                    </Form.Item>
                </Modal>
            </>
        );
    }
}

export default withRouter(manage)