import React, { Component } from 'react';
import { Breadcrumb, Input, Button, Row, Col, message, Form } from 'antd';
import {
    RollbackOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { API } from "../../util/config";
import axios from 'axios';
import '../../App.css';
import { DateTimeToday } from '../../util/formatDate'
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class malaysia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ipt_a1st: '',
            ipt_a2st: '',
            ipt_a3st: '',
            ipt_as1: '',
            ipt_as2: '',
            ipt_as3: '',
            ipt_as4: '',
            ipt_as5: '',
            ipt_as6: '',
            ipt_as7: '',
            ipt_as8: '',
            ipt_as9: '',
            ipt_as10: '',
            ipt_ac1: '',
            ipt_ac2: '',
            ipt_ac3: '',
            ipt_ac4: '',
            ipt_ac5: '',
            ipt_ac6: '',
            ipt_ac7: '',
            ipt_ac8: '',
            ipt_ac9: '',
            ipt_ac10: '',
            accessToken: '',
            stocks_dateTime: ''
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken })
            // this.getData()
        }
    }

    async getData() {
        const id = this.props.match.params.id
        await axios.get(`${API}/malaysia/id/${id}`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    console.log(data)
                    this.setState({
                        data: data.result,
                        // stocks_dateTime: data.result.award_date + " " + data.result.stocks_time,
                        ipt_a1st: data.result.award_malaysia_1st,
                        ipt_a2st: data.result.award_malaysia_2st,
                        ipt_a3st: data.result.award_malaysia_3st,
                        ipt_as1: data.result.award_malaysia_s1,
                        ipt_as2: data.result.award_malaysia_s2,
                        ipt_as3: data.result.award_malaysia_s3,
                        ipt_as4: data.result.award_malaysia_s4,
                        ipt_as5: data.result.award_malaysia_s5,
                        ipt_as6: data.result.award_malaysia_s6,
                        ipt_as7: data.result.award_malaysia_s7,
                        ipt_as8: data.result.award_malaysia_s8,
                        ipt_as9: data.result.award_malaysia_s9,
                        ipt_as10: data.result.award_malaysia_s10,
                        ipt_ac1: data.result.award_malaysia_c1,
                        ipt_ac2: data.result.award_malaysia_c2,
                        ipt_ac3: data.result.award_malaysia_c3,
                        ipt_ac4: data.result.award_malaysia_c4,
                        ipt_ac5: data.result.award_malaysia_c5,
                        ipt_ac6: data.result.award_malaysia_c6,
                        ipt_ac7: data.result.award_malaysia_c7,
                        ipt_ac8: data.result.award_malaysia_c8,
                        ipt_ac9: data.result.award_malaysia_c9,
                        ipt_ac10: data.result.award_malaysia_c10,
                    })
                    // console.log(data)
                } else if (!data.session) {
                    message.error(data.message)
                    this.props.history.push("/login");
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    checkTime(){
        // console.log("stocks_dateTime : ", this.state.stocks_dateTime)
        // console.log("DateTimeToday : ", DateTimeToday())
        if(DateTimeToday() < this.state.stocks_dateTime) {
            this.updateData()   
        }
    }

    async updateData() {
        if (this.state.ipt_a1 !== "") {
            const body = {
                id: this.props.match.params.id,
                a1st: this.state.ipt_a1st,
                a2st: this.state.ipt_a2st,
                a3st: this.state.ipt_a3st,
                as1: this.state.ipt_as1,
                as2: this.state.ipt_as2,
                as3: this.state.ipt_as3,
                as4: this.state.ipt_as4,
                as5: this.state.ipt_as5,
                as6: this.state.ipt_as6,
                as7: this.state.ipt_as7,
                as8: this.state.ipt_as8,
                as9: this.state.ipt_as9,
                as10: this.state.ipt_as10,
                ac1: this.state.ipt_ac1,
                ac2: this.state.ipt_ac2,
                ac3: this.state.ipt_ac3,
                ac4: this.state.ipt_ac4,
                ac5: this.state.ipt_ac5,
                ac6: this.state.ipt_ac6,
                ac7: this.state.ipt_ac7,
                ac8: this.state.ipt_ac8,
                ac9: this.state.ipt_ac9,
                ac10: this.state.ipt_ac10,
            }
            await axios.post(`${API}/malaysia/update`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
                .then(res => {
                    const data = res.data
                    if (data.status) {
                        message.success('บันทึกข้อมูลเสร็จสิ้น!!');
                        // console.log(data)
                    } else {
                        console.log(data.message)
                        message.error('เกิดข้อผิดพลาด!!');
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                    <Breadcrumb.Item>ออกรางวัล</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการตัวเลข</Breadcrumb.Item>
                </Breadcrumb>

                <br />

                <div style={{ minHeight: 280, padding: 24, marginBottom: 40, background: '#fff' }}>
                    <Form onSubmitCapture={() => this.checkTime()}>
                        <Row justify="end">
                            <Col span={24}>
                                <h3>งวดหวยวันที่ 8 มีนาคม 2556 - ฮานอย VIP</h3>
                                <p class="text-muted" style={{ marginTop: -10 }}>ตั้งค่าตัวเลขของงวดนี้</p>
                            </Col>

                            <Button shape="round" size="large" style={{ marginBottom: 20, marginRight: 10 }} onClick={() => this.props.history.goBack()} icon={<RollbackOutlined />}>ย้อนกลับ</Button>
                            <Button type="primary" htmlType="submit" shape="round" size="large" style={{ marginBottom: 20 }} icon={<SaveOutlined />} >บันทึก</Button>
                        </Row>

                        <div class="text-center text-light">
                            <Row style={{ backgroundColor: '#C70039', paddingTop: 15, paddingBottom: 5, marginBottom: 5 }}>
                                <Col span={24}>
                                    <h5 class="text-light">1st</h5>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_a1st}
                                        onChange={(e) => this.setState({ ipt_a1st: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                            <Row style={{ backgroundColor: '#FF5733', paddingTop: 15, paddingBottom: 5, marginBottom: 5 }}>
                                <Col span={24}>
                                    <h5 class="text-light">2st</h5>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_a2st}
                                        onChange={(e) => this.setState({ ipt_a2st: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>
                            <Row style={{ backgroundColor: '#FF5733', paddingTop: 15, paddingBottom: 5, marginBottom: 5 }}>
                                <Col span={24}>
                                    <h5 class="text-light">3st</h5>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_a3st}
                                        onChange={(e) => this.setState({ ipt_a3st: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                            </Row>

                            <Row className="input-list" style={{ backgroundColor: '#424949', paddingBottom: 20 }}>
                                <Col span={12}>
                                    <p>Special</p>
                                </Col>
                                <Col span={12}>
                                    <p>ปลอบใจ</p>
                                </Col>
                                {/* 1 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as1}
                                        onChange={(e) => this.setState({ ipt_as1: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac1}
                                        onChange={(e) => this.setState({ ipt_ac1: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 2 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as2}
                                        onChange={(e) => this.setState({ ipt_as2: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac2}
                                        onChange={(e) => this.setState({ ipt_ac2: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 3 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as3}
                                        onChange={(e) => this.setState({ ipt_as3: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac3}
                                        onChange={(e) => this.setState({ ipt_ac3: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 4 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as4}
                                        onChange={(e) => this.setState({ ipt_as4: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac4}
                                        onChange={(e) => this.setState({ ipt_ac4: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 5 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as5}
                                        onChange={(e) => this.setState({ ipt_as5: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac5}
                                        onChange={(e) => this.setState({ ipt_ac5: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 6 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as6}
                                        onChange={(e) => this.setState({ ipt_as6: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac6}
                                        onChange={(e) => this.setState({ ipt_ac6: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 7 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as7}
                                        onChange={(e) => this.setState({ ipt_as7: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac7}
                                        onChange={(e) => this.setState({ ipt_ac7: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 8 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as8}
                                        onChange={(e) => this.setState({ ipt_as8: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac8}
                                        onChange={(e) => this.setState({ ipt_ac8: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 9 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as9}
                                        onChange={(e) => this.setState({ ipt_as9: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac9}
                                        onChange={(e) => this.setState({ ipt_ac9: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                                {/* 10 */}
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_as10}
                                        onChange={(e) => this.setState({ ipt_as10: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>
                                <Col span={12}>
                                    <Input className="input-number" type="text" pattern="\d*" minLength="4" maxlength="4" size="large"
                                        value={this.state.ipt_ac10}
                                        onChange={(e) => this.setState({ ipt_ac10: e.target.value })}
                                    />
                                    <p><small></small></p>
                                </Col>

                            </Row>
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

export default withRouter(malaysia)